export default {
   process_status: [
      { value: 'WAITING', text: 'Waiting approval' },
      { value: 'COMPLETED', text: 'Completed' },
      { value: 'CANCELLED', text: 'Cancelled' },
   ],
   item_status: [
      { value: 'active', text: 'Active' },
      { value: 'inactive', text: 'Inactive' },
      // { value: 'PENDING', text: 'Pending' },
   ],
   job_status: [
      { value: 'PENDING', text: 'Pending' },
      { value: 'COMPLETED', text: 'Completed' },
      { value: 'ACCEPTED', text: 'Accepted' },
      { value: 'REJECTED', text: 'Rejected' },
   ],
   accept_status: [
      { value: 'ACCEPT', text: 'Accept' },
      { value: 'REJECT', text: 'Reject' },
   ],
   is_voucher_used_list: [
      { value: true, text: 'Yes' },
      { value: false, text: 'No' },
   ]
}