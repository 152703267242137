<template>
   <div>
      <v-row>
         <!-- <v-col cols="12">
            <v-row>
               <v-col cols="12">
                  <v-btn color="primary" class="m-auto" x-large :to="{ name: 'redeemRecordCreate' }" :disabled="isFetching">Add</v-btn>
               </v-col>
            </v-row>
         </v-col> -->
         <v-col cols="12">
            <Datatable
               :table-headers="tableHeaders"
               :table-data="formData"
               :page="tablePage"
               :page-limit="tablePageLimit"
               :page-limit-options="tablePageLimitOptions"
               :item-total="formItemTotal"
               :is-loading="tableLoading"
               :enable-search="false"
               :enable-export="false"
               :enable-filter="false"
               :enable-date-range="false"
               :enable-clear-all="false"
               :enable-refresh="false"
               :disable-pagination="tableLoading"
               :action-view-route-link="'redeemRecordDetail'"
               @options-update="onTableChange"
            />
         </v-col>
      </v-row>
   </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import masterData from '@/components/master-data'
import Datatable from '@/components/Datatable'

export default {
   name: 'RedeemRecord',
   components: {
      Datatable,
   },
   data() {
      return {
         tableHeaders: [
            { text: 'Redeem ID', value: 'id', sortable: false },
            { text: 'Create date', value: 'create_date', sortable: false },
            { text: 'User', value: 'user_id', sortable: false },
            { text: '', value: 'actionView', sortable: false, align: 'end' },
         ],
         tableLoading: false,
         tablePage: 1,
         tablePageLimit: 20,
         tablePageLimitOptions: [20, 50, 100],
         formItemTotal: 0,
         formData: [],
         dataOptions: {
            itemStatus: masterData.item_status,
         },
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),

      data() {
         return this.responseData.map(item => {
            const searchStatus = this.dataOptions.statusList.filter(x => x.value == item.status)
            const statusText = searchStatus.length ? searchStatus[0].text : ''
            return {
               ...item,
               statusText: statusText,
            }
         })
      },
   },
   methods: {
      ...mapActions(['sendRequest'], 'request'),
      formatData(data) {
         return data
      },
      async getData() {
         this.tableLoading = true
         const resp = await this.sendRequest({
            type: 'cms_get_redeem_record',
            data: {
               limit: this.tablePageLimit,
               page: this.tablePage - 1,
            }
         })
         if (!resp) {
            this.tableLoading = false
            return
         }
         this.formItemTotal = resp.total
         this.formData = this.formatData(resp.data)
         this.tableLoading = false
      },
      onTableChange(options) {
         if (options.itemsPerPage !== this.tablePageLimit) {
            this.tablePage = 1 // reset to first page if view options changed
         } else {
            this.tablePage = options.page
         }

         if (options.sortBy.length > 0) {
            this.tableSortKey = options.sortBy[0]
         }
         this.tableSortDesc = options.sortDesc.length > 0 ? options.sortDesc[0] : true

         this.tablePageLimit = options.itemsPerPage
         this.getData()
      },
   },
   async mounted() {
      await this.getData()
   }
}
</script>
