<template>
  <div>
    <v-card class="custom-table">
      <v-toolbar flat height="auto" class="custom-table-toolbar" v-if="false">
        <v-toolbar-items
          class="d-flex align-center"
          :class="{ 'flex-wrap': $vuetify.breakpoint.width < 1280 }"
          style="width: 100%"
        >
          <v-switch
            v-if="enableSelectToggle"
            v-model="tableTickboxToggle"
            class="mr-5 mt-2 mb-3"
            inset
            dense
            hide-details
            label="啟用勾選"
          ></v-switch>
          <v-switch
            v-if="enableWeekdayToggle"
            v-model="tableWeekdayToggle"
            class="mr-5"
            inset
            dense
            hide-details
            label="顯示禮拜"
          ></v-switch>
          <v-text-field
            v-if="enableSearch"
            v-model="searchText"
            class="mr-3 mt-2 mb-3"
            style="width: 100%; max-width: 320px"
            :append-icon="mdiMagnify"
            :label="searchPlaceholder"
            single-line
            outlined
            dense
            hide-details
            clearable
            @click:clear="clearSearch()"
            @click:append="clickSearch()"
            @keydown.enter="clickSearch()"
            @input="inputSearch()"
          ></v-text-field>
          <v-menu
            v-if="enableDateRange"
            ref="dateRangeMenu"
            v-model="dateRangeMenu"
            style="width: 100%; max-width: 320px"
            :close-on-content-click="false"
            :return-value.sync="searchDateRange"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mr-3 mt-2 mb-3"
                style="width: 100%; max-width: 320px"
                :value="displayCustomDateRange(searchDateRange)"
                :label="dateRangePlaceholder"
                :append-icon="mdiCalendarSearch"
                single-line
                outlined
                dense
                hide-details
                clearable
                readonly
                v-bind="attrs"
                @click:clear="clearDateRange()"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchDateRange"
              range
              no-title
              scrollabel
              :first-day-of-week="0"
              :day-format="$Formatter.dayFormat"
              locale="zh-hk"
            >
              <v-spacer></v-spacer>
              <v-btn text small color="error" @click="dateRangeMenu = false">
                <v-icon class="mr-1" small>
                  {{ mdiClose }}
                </v-icon>
                取消
              </v-btn>
              <v-btn
                text
                small
                color="primary"
                @click="
                  $refs.dateRangeMenu.save(searchDateRange)
                  selectDateRange()
                "
              >
                <v-icon class="mr-1" small>
                  {{ mdiCheck }}
                </v-icon>
                確定
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-select
            v-if="enableFilter"
            v-model="tableFilterModel"
            class="mr-3 mt-2 mb-3"
            style="width: 100%; max-width: 320px"
            :items="tableFilterData"
            :menu-props="{ offsetY: true }"
            outlined
            hide-details
            dense
            @change="changeFilter()"
          ></v-select>
        </v-toolbar-items>
        <v-spacer></v-spacer>
        <v-toolbar-items class="d-flex align-center">
          <v-btn v-if="enableClearAll" text small color="error" :plain="!$vuetify.theme.dark" @click="clickClearAll()">
            <v-icon class="mr-1" color="error">
              {{ mdiClose }}
            </v-icon>
            清除搜尋
          </v-btn>
          <v-btn v-if="enableRefresh" text small color="success" :plain="!$vuetify.theme.dark" @click="clickRefresh()">
            <v-icon class="mr-1" color="success">
              {{ mdiRefresh }}
            </v-icon>
            重新載入
          </v-btn>
          <v-btn
            v-if="enableExport"
            text
            small
            color="primary"
            :plain="!$vuetify.theme.dark"
            :href="exportUrlLocation"
            target="_blank"
          >
            <v-icon class="mr-1" color="primary">
              {{ mdiTableArrowRight }}
            </v-icon>
            匯出資料
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        class="custom-table"
        locale="zh-hk"
        :loading="isLoading"
        :headers="tableHeaders"
        :items="tableData"
        :items-per-page="pageLimit"
        :page="page"
        :server-items-length="itemTotal"
        :options.sync="options"
        :height="isFixedHeader ? 'calc(3.125rem * ' + (pageLimit + 1.1) + ')' : ''"
        :fixed-header="isFixedHeader"
        :show-select="enableSelectToggle && tableTickboxToggle"
        :show-expand="enableExpand"
        :show-group-by="enableGroupBy"
        :single-select="isSingleSelect"
        :single-expand="isSingleExpand"
        :hide-default-header="disableHeader"
        :hide-default-footer="disableFooter"
        :disable-filtering="disableFiltering"
        :disable-pagination="disablePagination"
        :disable-sort="disableSort"
        :multi-sort="isMultiSort"
        :must-sort="isMustSort"
        :sort-by="defaultSortKey"
        :sort-desc="defaultSortDesc"
        :header-props="{
          sortByText: 'Sort By',
        }"
        :footer-props="{
          showCurrentPage: true,
          showFirstLastPage: $vuetify.breakpoint.xsOnly ? false : enableFirstLastPage,
          disablePagination: disablePagination,
          itemsPerPageText: 'Show:',
          itemsPerPageOptions: pageLimitOptions,
          itemsPerPageAllText: 'All',
          pageText:
            'Show ' +
            (tableData.length > 1
              ? (page - 1) * pageLimit + 1 + '-' + Math.min(page * pageLimit, itemTotal)
              : (page - 1) * pageLimit + 1) +
            ' items',
        }"
        loading-text="Processing"
        no-data-text="No Data"
        no-results-text="No Data"
        :dense="isDense"
        :mobile-breakpoint="breakpoint"
      >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            class="hide-page-select"
            :show-current-page="true"
            :disable-items-per-page="true"
            :pagination="pagination"
            :options="options"
            :show-first-last-page="$vuetify.breakpoint.xsOnly ? false : enableFirstLastPage"
            :items-per-page-text="''"
            :page-text="
              'Total ' +
              (itemTotal % pageLimit == 0 ? itemTotal / pageLimit : parseInt(itemTotal / pageLimit) + 1) +
              ' page / ' +
              itemTotal +
              ' items'
            "
            @update:options="updateOptions"
          >
          </v-data-footer>
        </template>

        <template v-slot:[`item.create_date`]="{ item }">
          {{ item.create_date.split('T')[0] + ' ' + item.create_date.split('T')[1].substring(0, 8) }}
        </template>

        <template v-slot:[`item.return_after_five`]="{ item }">
          {{ item.return_after_five ? 'Yes' : 'No' }}
        </template>

        <template v-slot:[`item.return_in_five`]="{ item }">
          {{ item.return_in_five ? 'Yes' : 'No' }}
        </template>

        <template v-slot:[`item.return_rate_after_five`]="{ item }">
          {{ item.return_rate_after_five + '%' }}
        </template>

        <template v-slot:[`item.return_rate_in_five`]="{ item }">
          {{ item.return_rate_in_five + '%' }}
        </template>

        <template v-slot:[`item.phone`]="{ item }">
          {{
            item.phone !== undefined && item.phone.length === 8
              ? item.phone.substring(0, 4) + ' ' + item.phone.substring(4)
              : item.phone
          }}
        </template>

        <template v-slot:[`item.refund_status`]="{ item }">
          {{ item.refund_status | format_refund_status }}
        </template>

        <template v-slot:[`item.actionDetails`]="{ item }">
          <v-btn text color="secondary" small @click="emitDetailsClickEvent(item.id)">
            <v-icon class="mr-1" small>
              {{ mdiEyeOutline }}
            </v-icon>
            查看
          </v-btn>
        </template>

        <template v-slot:[`item.actionView`]="{ item }">
          <v-btn
            text
            color="primary"
            small
            :to="{ name: actionViewRouteLink, params: { id: item.id } }"
            :disabled="disablePagination"
          >
            <v-icon class="mr-1" small>
              {{ mdiEyeOutline }}
            </v-icon>
            查看
          </v-btn>
        </template>

        <template v-slot:[`item.actionViewWithParams`]="{ item }">
          <v-btn
            text
            color="primary"
            small
            :to="{
              name: actionViewRouteLink,
              params: { type: item.type },
            }"
          >
            <v-icon class="mr-1" small>
              {{ mdiEyeOutline }}
            </v-icon>
            查看
          </v-btn>
        </template>

        <template v-slot:[`item.actionViewEditDelete`]="{ item }">
          <v-btn
            text
            color="primary"
            small
            :loading="submitLoading"
            @click="emitTableActionEvent('edit', item.id)"
            :disabled="disablePagination"
          >
            <v-icon class="mr-1" small>
              {{ mdiPencil }}
            </v-icon>
            Edit
          </v-btn>

          <v-btn
            class="mr-1"
            text
            color="error"
            small
            :loading="submitLoading"
            @click="emitTableActionEvent('remove', item.id)"
            :disabled="disablePagination"
          >
            <v-icon class="mr-1" small>
              {{ mdiTrashCanOutline }}
            </v-icon>
            Remove
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
import {
  mdiEyeOutline,
  mdiTrashCanOutline,
  mdiCheckCircle,
  mdiAlert,
  mdiAlertOutline,
  mdiCancel,
  mdiCheck,
  mdiClose,
  mdiDeleteForever,
  mdiRefresh,
  mdiMagnify,
  mdiCalendarSearch,
  mdiNotePlusOutline,
  mdiTableArrowRight,
  mdiEmail,
  mdiPhone,
  mdiCalendarClock,
  mdiCardAccountDetails,
  mdiPencil,
} from '@mdi/js'

export default {
  // ------ page properties ------
  name: 'Datatable',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    tableFilterData: {
      type: Array,
      default: () => [{ text: '全部', value: 'all' }],
    },
    itemTotal: {
      type: Number,
      default: 0,
    },
    defaultSortKey: {
      type: Array,
      default: () => ['create_date'],
    },
    defaultSortDesc: {
      type: Array,
      default: () => [true],
    },
    isSingleSelect: {
      type: Boolean,
      default: false,
    },
    isSingleExpand: {
      type: Boolean,
      default: false,
    },
    isMustSort: {
      type: Boolean,
      default: true,
    },
    isMultiSort: {
      type: Boolean,
      default: false,
    },
    isFixedHeader: {
      type: Boolean,
      default: false,
    },
    isDense: {
      type: Boolean,
      default: false,
    },
    enableSelectToggle: {
      type: Boolean,
      default: false,
    },
    enableWeekdayToggle: {
      type: Boolean,
      default: false,
    },
    enableRefresh: {
      type: Boolean,
      default: true,
    },
    enableExport: {
      type: Boolean,
      default: false,
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    enableFilter: {
      type: Boolean,
      default: false,
    },
    enableDateRange: {
      type: Boolean,
      default: false,
    },
    enableClearAll: {
      type: Boolean,
      default: false,
    },
    enableExpand: {
      type: Boolean,
      default: false,
    },
    enableGroupBy: {
      type: Boolean,
      default: false,
    },
    enableFirstLastPage: {
      type: Boolean,
      default: true,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    disableFiltering: {
      type: Boolean,
      default: false,
    },
    disableHeader: {
      type: Boolean,
      default: false,
    },
    disableFooter: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    pageLimitOptions: {
      type: Array,
      default: () => [5, 10, 20, 50],
    },
    page: {
      type: Number,
      default: 1,
    },
    pageLimit: {
      type: Number,
      default: 10,
    },
    breakpoint: {
      type: Number,
      default: 960,
    },
    actionViewRouteLink: {
      type: String,
      default: '',
    },
    searchPlaceholder: {
      type: String,
      default: '搜尋',
    },
    dateRangePlaceholder: {
      type: String,
      default: '按日期範圍搜尋',
    },
    confirmDeleteDes: {
      type: String,
      default: '此動作將無法還原',
    },
    exportUrl: {
      type: String,
      default: '/api/cms-export',
    },
    submitLoading: {
      type: Boolean,
      default: false,
    },
  },

  // ------ page variables ------
  data: () => ({
    tableInit: false,
    tableWeekdayToggle: true,
    tableTickboxToggle: false,
    tableFilterModel: 'all',
    options: {},
    dialogConfirmDelete: {
      show: false,
      loading: false,
      data_type: '',
      data_id: -1,
    },
    searchText: '',
    searchDateRange: [],
    dateRangeMenu: false,

    dataDeleted: false,
    dataFailedToDelete: false,

    // icon
    mdiEyeOutline,
    mdiTrashCanOutline,
    mdiAlertOutline,
    mdiCancel,
    mdiCheck,
    mdiClose,
    mdiDeleteForever,
    mdiRefresh,
    mdiMagnify,
    mdiCalendarSearch,
    mdiNotePlusOutline,
    mdiCheckCircle,
    mdiAlert,
    mdiTableArrowRight,
    mdiEmail,
    mdiPhone,
    mdiCalendarClock,
    mdiCardAccountDetails,
    mdiPencil,
  }),

  // ------ computed values ------
  computed: {
    exportUrlLocation() {
      let url = this.exportUrl + '?sort='
      if (this.options && this.options.sortBy && this.options.sortBy.length) {
        url += this.options.sortBy[0]
      } else {
        url += 'create_date'
      }
      if (this.options && this.options.sortDesc && this.options.sortDesc.length) {
        url += '&order=' + ((this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : true) ? 'desc' : 'asc')
      } else {
        url += '&order=desc'
      }
      if (this.searchText !== '') {
        url += '&search=' + encodeURIComponent(this.searchText)
      }

      if (this.searchDateRange.length >= 2) {
        url += '&start_date=' + this.searchDateRange[0]
        url += '&end_date=' + this.searchDateRange[1]
      } else if (this.searchDateRange.length === 1) {
        url += '&start_date=' + this.searchDateRange[0]
        url += '&end_date=' + this.searchDateRange[0]
      }

      return url
    },
  },

  // ------ mutation observer ------
  watch: {
    options: {
      handler() {
        this.updateOptions()
      },
      deep: true,
    },
  },

  // ------ life cycle events ------
  created() {},

  // ------ page functions ------
  methods: {
    displayCustomDateRange(date) {
      if (date.length === 2) {
        if (new Date(date[0]).getTime() > new Date(date[1]).getTime()) {
          return `${date[1]} (${this.$Formatter.displayWeekday(date[1])}) ~ ${
            date[0]
          } (${this.$Formatter.displayWeekday(date[0])})`
        } else {
          return `${date[0]} (${this.$Formatter.displayWeekday(date[0])}) ~ ${
            date[1]
          } (${this.$Formatter.displayWeekday(date[1])})`
        }
      } else if (date.length === 1) {
        return `${date[0]} (${this.$Formatter.displayWeekday(date[0])})`
      }

      return ''
    },
    updateOptions() {
      if (this.tableInit) {
        this.$emit('options-update', this.options)
      }
      this.tableInit = true
    },
    clickRefresh() {
      this.$emit('refresh-clicked')
    },
    clickExport() {
      this.$emit('export-clicked')
    },
    clickSearch() {
      this.$emit('search-clicked', this.searchText)
    },
    inputSearch() {
      this.$emit('search-input', this.searchText)
    },
    clearSearch() {
      this.$emit('search-cleared')
    },
    selectDateRange() {
      if (this.searchDateRange.length === 2) {
        if (new Date(this.searchDateRange[0]).getTime() > new Date(this.searchDateRange[1]).getTime()) {
          this.$emit('daterange-selected', [this.searchDateRange[1], this.searchDateRange[0]])
        } else {
          this.$emit('daterange-selected', this.searchDateRange)
        }
      } else {
        this.$emit('daterange-selected', this.searchDateRange)
      }
    },
    clearDateRange() {
      this.searchDateRange = []
      this.$emit('daterange-cleared')
    },
    clickClearAll() {
      this.searchText = ''
      this.searchDateRange = []
      this.tableFilterModel = 'all'
      this.$emit('all-search-cleared')
    },
    changeFilter() {
      this.$emit('filter-changed', this.tableFilterModel)
    },
    emitDetailsClickEvent(dataId) {
      this.$emit('details-clicked', dataId)
    },
    emitTableActionEvent(type, id) {
      this.$emit('tableAction', type, id)
    },
  },
}
</script>

<style>
.v-data-table.custom-table .v-data-table-header-mobile .v-text-field .v-select__selections {
  margin-top: 0.5rem;
}
.custom-table-toolbar .v-input--selection-controls .v-input__slot > .v-label {
  font-size: 0.875rem;
}

.custom-table-toolbar .v-input input,
.custom-table-toolbar .v-input label {
  font-size: 0.825rem;
}

.v-data-table.custom-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table.custom-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table.custom-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 0.825rem;
  font-weight: 500;
}

.v-data-table.custom-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  padding-top: 1rem;
}
.v-data-table.custom-table > .v-data-table__wrapper .v-data-table__mobile-row:last-of-type {
  padding-bottom: 1rem;
}
.v-data-table.custom-table > .v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 2.2rem;
}

.hide-page-select .v-data-footer__select {
  visibility: none;
  opacity: 0;
  pointer-events: none;
}

.v-dialog.table-dialog {
  margin: 0 auto;
}

@media screen and (max-width: 1600px) {
  .custom-table-toolbar .v-toolbar__content {
    flex-wrap: wrap;
  }
  .custom-table-toolbar .v-toolbar__content .spacer {
    display: none;
  }
}
</style>
